// Section -> Chapter -> Module

export type SectionID =
  | 'bronze'
  | 'silver'
  |  'gold'

export type Chapter = {
  name: string;
  items: string[];
  description?: string;
};

const MODULE_ORDERING: { [key in SectionID]: Chapter[] } = {
  bronze: [
    {
      name: 'Beginner',
      items: [
        'time-comp',
        'sea-saw',
        'single-windmill',
        'twin-windmill',
        'ferris-wheel',
        'rect-geo'],
    },
    {
      name: 'Intermediate',
      items: [
        'little-train',
        'little-carousel',
        'little-motorcycle',
        'little-helicopter',
        'little-blender'],
    },
    {
      name: 'Advanced',
      items: [
        'little-car',
        'line-tracking'],
    },
  ],
  silver: [
    {
      name: 'Robotics',
      items: [
        'robot_lesson0',
        'robot_lesson1', 
        'robot_lesson2',
        'robot_lesson3', 
        'robot_lesson4',
        'robot_lesson5',
        'robot_lesson6',
        'robot_lesson7'],
    },
  ],
  gold: [
    {
      name: 'Coding',
      items: [
        'code_lesson0',
        'code_lesson1', 
        'code_lesson2',
        'code_lesson3', 
        'code_lesson5',
        'code_lesson6',
        'code_lesson7'],
    },
  ],
};

export default MODULE_ORDERING;
export const SECTIONS: SectionID[] = Object.keys(
  MODULE_ORDERING
) as SectionID[];
export const SECTION_LABELS: { [key in SectionID]: string } = {
  bronze: 'Mechatronics',
  silver: 'Robotics',
  gold: 'Coding',
};
export const SECTION_SEO_DESCRIPTION: { [key in SectionID]: string } = {
  bronze: 'Mechatronics',
  silver: 'Robotics',
  gold: 'Coding',
};
export const SECTION_SEO_TITLES: { [key in SectionID]: string } = {
  bronze: 'Mechatronics',
  silver: 'Robotics',
  gold: 'Coding',
};

const moduleIDToSectionMap: { [key: string]: SectionID } = {};

SECTIONS.forEach(section => {
  MODULE_ORDERING[section].forEach(category => {
    category.items.forEach(moduleID => {
      moduleIDToSectionMap[moduleID] = section;
    });
  });
});

export { moduleIDToSectionMap };
export { moduleIDToURLMap };

const moduleIDToURLMap: { [key: string]: string } = {};


SECTIONS.forEach(section => {
  MODULE_ORDERING[section].forEach(category => {
    category.items.forEach(moduleID => {
      moduleIDToURLMap[moduleID] = `/${section}/${moduleID}`;
    });
  });
});
